<template>
  <nav
    id="nav"
    class="navbar d-flex justify-content-between align-items-center"
  >
    <div @click="toggleNav" class="product-range" :class="{ show: showNav }">
      <ClientOnly>
        <i class="fa fa-times"></i>
        <i class="fa fa-bars"></i>
      </ClientOnly>

      <span class="ml-1">{{ $t("view_our_range") }}</span>
      <NavbarContent v-if="showNav" @toggleNav="toggleNav" />
    </div>
    <NavbarRightMenu class="desktop-navlinks" />
    <div
      class="nav-overlay"
      @click="toggleNav"
      :class="{ show: showNav }"
    ></div>

    <!-- <b-link href="#" class="nav-opener" @click="toggleNav"
      ><span></span
    ></b-link>
    <div class="fake-nav-overlay" :class="{ show: navExpanded }"></div>
    <transition name="slide-fade">
      <div class="nav-collapse" :class="{ show: navExpanded }">
        <NavbarContent @clicked="toggleNav" />
      </div>
    </transition> -->
  </nav>
</template>

<script>
import ClientOnly from "vue-client-only";
import NavbarContent from "./NavbarContent.vue";
import NavbarRightMenu from "@/base/core/components/header/NavbarRightMenu";
import { Logger } from "@storefront/core/lib/logger";

export default {
  name: "Navbar",
  components: {
    ClientOnly,
    NavbarContent,
    NavbarRightMenu,
  },
  data: () => ({
    showNav: false,
  }),
  computed: {
    navExpanded: {
      get() {
        return this.$store.getters["menu/getNavbar"];
      },
      set(val) {
        Logger.debug("navExpanded set called", "Navbar", val)();
      },
    },
  },

  mounted() {
    window.addEventListener("resize", this.resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    onClick() {},
    toggleNav() {
      // this.$store.dispatch("menu/toggleNavbar");
      this.showNav = !this.showNav;
    },
    resize() {
      document.body.classList.add("resize-active");
      if (window.innerWidth > 991) this.navExpanded = false;
      clearTimeout(this.resizeTimer);
      this.resizeTimer();
    },
    resizeTimer() {
      setTimeout(() => {
        document.body.classList.remove("resize-active");
      }, 400);
    },
  },
};
</script>

<style lang="scss" scoped></style>
