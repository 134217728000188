<template>
  <cmsBlockSimple
    v-if="typeof footerTopData == 'object'"
    :identifier="footerTopData.identifier"
  />
</template>

<script>
import cmsBlockSimple from "@/base/core/components/core/BlockSimple";

export default {
  name: "FooterTopContent",
  components: {
    cmsBlockSimple,
  },
  computed: {
    footerTopData() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "footer_top_content"
      );
    },
  },
};
</script>
