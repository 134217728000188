<template>
  <div>
    <!-- <div>
      <div class="account-address-book">
        Als eerste op de hoogte!
        <h1>{{ $t("newsletter_subscriptions") }}</h1>
        <div class="address-block">
          <h2>{{ $t("newsletter_subscription_option") }}</h2>
          <b-row>
            <b-col sm="12">
              <b-form-checkbox
                id="checkbox-1"
                name="checkbox-1"
                label-for="checkbox1"
                v-model="is_subscribed"
                >{{ $t("newsletter_subscription_selected") }}</b-form-checkbox
              >
            </b-col>
          </b-row>
        </div>

        <div class="form-footer">
          <b-button
            @click="save"
            variant="info"
            class="text-white form-submit-btn"
            >{{ $t("save") }}</b-button
          >
        </div>
      </div>
    </div> -->
    <div class="newsletter-wrap d-flex justify-content-between pb-4 mb-4">
      <b-row class="align-items-end">
        <b-col cols="12" lg="3" md="6">
          <div class="newsletter-content">
            <h4>{{ $t("be_the_first_to_know") }}</h4>
            <div class="sub-title">
              {{ $t("newsletter_sub_title") }}
            </div>
          </div>
        </b-col>
        <b-col cols="12" lg="5" md="6">
          <div class="newsletter-form">
            <b-form-input
              v-model="email"
              type="email"
              id="subscribe--email"
              :placeholder="$t('newsletter_placeholder')"
              class="text-dark"
              aria-label="Email"
            ></b-form-input>
            <button
              type="button"
              @click="subscribe(email)"
              class="btn subscribe-button"
            >
              {{ $t("subscribe_newsletter") }}
            </button>
          </div>
        </b-col>
        <b-col cols="12" lg="4" md="12" class="mt-md-5">
          <div
            class="
              d-flex
              justify-content-sm-end justify-content-center
              mt-md-0 mt-sm-4 mt-4
            "
          >
            <div v-if="typeof socialIcons == 'object'">
              <ul class="social-icons">
                <li v-for="(image, index) in socialIcons.slides" :key="index">
                  <b-link
                    class="img"
                    :href="image.link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      :src="image.media.url"
                      :alt="image.title"
                      class="social_icons"
                    />
                  </b-link>
                </li>
              </ul>
            </div>

            <!-- <li v-for="socialIcon in socialIcons" :key="socialIcon.id">
                <a :href="socialIcon.linkTo" target="_blank">
                  <i class="fas" :class="'fa-' + socialIcon.iconName"></i>
                </a>
              </li> -->
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { Logger } from "@storefront/core/lib/logger";
import User from "@storefront/core/modules/user/mixins";

export default {
  name: "NewsletterSubscription",
  mixins: [User],
  components: {},
  mounted() {
    Logger.debug("user", "NewsletterSubscription", this.user.is_subscribed)();
    this.is_subscribed = this.user.is_subscribed;
    Logger.debug(
      "this.is_subscribed",
      "NewsletterSubscription",
      this.is_subscribed
    )();
  },
  data: () => ({
    email: "",
    is_subscribed: false,
  }),
  computed: {
    getFilter() {
      return [1, 2, 3, 4, 5, 6];
    },
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
      },
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    socialIcons() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "social_icons"
      );
    },
  },
  methods: {
    save() {
      this.$store.dispatch("user/updateUserNewsletter", {
        is_subscribed: this.is_subscribed,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
