<template>
  <footer id="footer" class="footer">
    <!-- <back-to-top class="back-to-top"></back-to-top> -->
    <!-- <FooterDeliverySeparator /> -->

    <FooterTopContent />
    <div class="main-footer" v-if="!ischeckoutpage">
      <div class="container">
        <NewsletterSubscription />
        <div class="quick-links-wrap">
          <b-row>
            <b-col cols="12" lg="3" md="6" sm="12">
              <cmsBlockSimple
                v-if="typeof footerImgCms == 'object'"
                :identifier="footerImgCms.identifier"
              />
            </b-col>
            <b-col
              cols="12"
              lg="3"
              md="6"
              v-for="(data, index) in accdata"
              :key="index"
            >
              <div class="quick-links">
                <cmsBlockSimple
                  v-if="typeof data == 'object'"
                  :identifier="data.identifier"
                />
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div class="main-footer" v-else>
      <div class="text-center">
        <cmsBlockSimple
            v-if="typeof footerCheckoutCms == 'object'"
            :identifier="footerCheckoutCms.identifier"
        />
      </div>
    </div>
    <FooterBottom />
  </footer>
</template>

<script>
import { Logger } from "@storefront/core/lib/logger";
import cmsBlockSimple from "@/base/core/components/core/BlockSimple";
import FooterTopContent from "@/base/core/components/footer/FooterTopContent";
import FooterBottom from "@/base/core/components/footer/FooterBottom";
import NewsletterSubscription from "@/base/core/components/footer/NewsletterSubscription";

export default {
  name: "Footer",
  components: {
    FooterTopContent,
    FooterBottom,
    NewsletterSubscription,
    cmsBlockSimple,
  },
  mounted() {
    Logger.debug("gtm2", "Footer.vue", this.$gtm.enabled())();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  computed: {
    accdata() {
      return [
        this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
          "footer_menu_service"
        ),
        this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
          "footer_menu_quick_links"
        ),
        this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
          "footer_menu_information"
        ),
      ];
    },
    footerImgCms() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "footer_image"
      );
    },
    footerCheckoutCms() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
          "footer_checkout_info"
      );
    },
    allCookies() {
      return this.$gtm.enabled();
    },
    deliveryPartners() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "delivery_partners"
      );
    },
    contactData() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "footer_contact"
      );
    },
  },
  watch: {
    allCookies(oldV, newV) {
      Logger.debug("allCookies watch oldV", "Footer.vue", oldV)();
      Logger.debug("allCookies watch newV", "Footer.vue", newV)();
    },
  },
  data() {
    return {
      email: "",
      ischeckoutpage: false
    };
  },
  created(){
    if(this.$route.path == '/checkout'){
      this.ischeckoutpage = true
    }
    else{
      this.ischeckoutpage = false
    }
  }
};
</script>

<style lang="scss" scoped></style>
