<template>
  <div class="nav-form homepage-search-form">
    <div class="mobile-search">
      <client-only>
        <b-link class="" @click.prevent="toggleSearch"
          ><i class="fa fa-search"></i
        ></b-link>
      </client-only>
    </div>
    <div class="search-group" :class="{ show: isSearchGroupShown }">
      <!-- <b-btn v-on:click="goClose()" class="close-btn" variant="light" squared
        >X</b-btn
      > -->
      <!--//Disable Magento search Ticket #8836
      <b-form-input
        v-model="search"
        type="text"
        required
        id="search"
        @focus="activeInSearch = true"
        @blur="activeInSearch = false"
        :placeholder="$t('search_placeholder')"
        v-on:keyup.enter="
          activeInSearch = false;
          goSearch();
        "
        class="search-input"
        :class="{ focused: isSearchResultsShown }"
      ></b-form-input>-->
      <b-form-input
        v-model="search"
        type="text"
        required
        id="search"
        @focus="activeInSearch = true"
        @blur="activeInSearch = false"
        :placeholder="$t('search_placeholder')"
        class="search-input"
        :class="{ focused: isSearchResultsShown }"
      ></b-form-input>
      <client-only>
        <!-- //Disable Magento search Ticket #8836
       <b-btn
          v-on:click="
            activeInSearch = false;
            goSearch();
          "
          class="search-btn"
          variant="light"
          squared
          ><i class="fa fa-search"></i
        ></b-btn> -->
        <b-btn class="search-btn" variant="light" squared
          ><i class="fa fa-search"></i
        ></b-btn>
      </client-only>

      <div
        class="search-results fancy-scroll"
        :class="{ show: isSearchResultsShown }"
      >
        <span class="search--title" v-if="searchSuggestions"
          >{{ $t("suggestions") }}:</span
        >
        <ul class="search-suggestions">
          <li
            v-for="suggestion of searchSuggestions"
            :key="suggestion.word"
            class="d-block"
          >
            <b-link v-on:click="goRoute('/search', suggestion.word)">{{
              suggestion.word
            }}</b-link>
          </li>
        </ul>
        <!-- <span class="search--title">{{ $t("recommended_products") }}:</span> -->
        <ul class="recommended-products">
          <!--dfsdfs  -->
          <li v-if="!recommendedProducts.length" class="text-center">
            {{ $t("no_data_found") }}
          </li>
          <li
            v-for="recommendedProduct of recommendedProducts"
            :key="recommendedProduct.sku"
            class="d-inline-block"
          >
            <b-link
              v-on:click="goRoute('/' + recommendedProduct.url_key)"
              class="d-flex align-items-center"
            >
              <div class="search-results-wrap">
                <div class="product-img">
                  <!-- :src="recommendedProduct.thumbnail.small" -->
                  <b-img
                    :src="recommendedProduct.thumbnail.url"
                    :alt="recommendedProduct.name"
                    width="41"
                    height="51"
                    fluid
                  ></b-img>
                </div>
                <div class="search-product-detail">
                  <div class="product-name">{{ recommendedProduct.name }}</div>
                  <div>
                    <Rating
                      :stars="recommendedProduct.rating_summary"
                      :noOfRatings="recommendedProduct.review_count"
                    />
                  </div>
                  <div class="search-product-price">
                    <div
                      v-if="
                        recommendedProduct.price_range.minimum_price.discount
                          .percent_off >= 5
                      "
                      class="d-flex align-items-end price-selection"
                    >
                      <span class="recommended-retail-price">
                        <span>{{ $t("advice_price") }}:</span>
                        <span class="original-price">
                          <span class="price-stroke"></span>
                          {{
                            formatPrice(
                              recommendedProduct.price_range.minimum_price
                                .regular_price.value
                            )
                          }}
                        </span></span
                      >
                    </div>
                    <span
                      v-if="
                        recommendedProduct.price_range.minimum_price.final_price
                          .value ==
                        recommendedProduct.price_range.maximum_price.final_price
                          .value
                      "
                      class="product-price"
                    >
                      {{
                        formatPrice(
                          recommendedProduct.price_range.minimum_price
                            .final_price.value
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </b-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@config";
import ClientOnly from "vue-client-only";
import { getProductsBySearchPreview } from "@storefront/core/data-resolver/products";
import { Logger } from "@storefront/core/lib/logger";
import Rating from "@/base/core/components/products/category-product/Rating.vue";

export default {
  name: "HeaderSearch",
  components: {
    Rating,
    ClientOnly,
  },
  data: () => ({
    shop_url: config.shop.url,
    search: "",
    activeInSearch: false,
    searchlTimer: null,
    seachCloseTimer: null,
    closeSignal: null,
    output: null,
    searchSuggestions: [],
    recommendedProducts: [],
    isSearchGroupShown: false,
    isSearchResultsShown: false,
  }),
  mounted() {
    window.addEventListener("resize", this.resize);
    document.addEventListener("click", this.close);
  },
  destroyed() {
    document.removeEventListener("click", this.close);
    window.removeEventListener("click", this.resize);
  },
  methods: {
    resize() {
      document.body.classList.add("resize-active");
      if (window.innerWidth > 575) {
        this.isSearchGroupShown = false;
      }
      clearTimeout(this.resizeTimer);
      this.resizeTimer();
    },
    resizeTimer() {
      setTimeout(() => {
        document.body.classList.remove("resize-active");
      }, 400);
    },
    toggleSearch() {
      this.isSearchGroupShown = !this.isSearchGroupShown;
    },
    close(e) {
      if (!this.$el.parentElement.contains(e.target)) {
        this.isSearchGroupShown = false;
        this.isSearchResultsShown = false;
      }
    },
    showSearchResults() {
      this.isSearchResultsShown = true;
    },
    hideSearchResults() {
      this.isSearchResultsShown = false;
    },
    async getSearchPreview() {
      const controller = new AbortController();
      const { signal } = controller;

      this.closeSignal = controller;
      this.output = await getProductsBySearchPreview(this.search, signal);
      this.closeSignal = null;
      this.recommendedProducts = this.output.items;
      this.searchSuggestions = this.output.search_suggestions;
      if (this.activeInSearch == true) {
        this.showSearchResults();
      } else {
        this.hideSearchResults();
      }
    },
    goRoute(route, query = null) {
      Logger.debug("goRoute", "HeaderSearch", route)();
      this.hideSearchResults();
      this.goClose();
      this.search = "";
      if (query != null) {
        this.$router.push({ path: route, query: { q: query } });
      } else {
        this.$router.push({ path: route });
      }
    },
    async goSearch() {
      this.hideSearchResults();
      this.goClose();
      if (this.search.length > 0) {
        if (this.$gtm.enabled()) {
          if (typeof window.fbq === "function") {
            window.fbq("track", "Search");
          }
        }

        clearTimeout(this.searchlTimer);
        if (this.closeSignal !== null) this.closeSignal.abort();

        const response = await this.$store.dispatch(
          "product1/searchBeforeEnter",
          this.search
        );

        if (response && response.redirect_url) {
          if (response.redirect_url.includes(this.shop_url)) {
            const path = response.redirect_url.replace(this.shop_url, "/");
            this.$router.push({ path });
          } else {
            window.location = response.redirect_url;
          }
        } else {
          this.$router.push({ path: "/search", query: { q: this.search } });
        }
      }
    },
    goClose() {
      this.close(this);
    },
    hideSearchResultsTimer() {
      Logger.debug("hideSearchResultsTimer", "HeaderSearch", null)();
      this.seachCloseTimer = setTimeout(
        function (scope) {
          scope.search = "";
          scope.hideSearchResults();
        },
        300,
        this
      );
    },
    formatPrice(price) {
      if (price % 1 === 0) {
        return "€ " + price + ",-";
      }
      return "€ " + price.toFixed(2).replace(".", ",");
    },
  },
  watch: {
    search() {
      if (this.search.length > 2) {
        if (this.searchlTimer !== null) {
          clearTimeout(this.searchlTimer);
        }
        this.searchlTimer = setTimeout(
          function (scope) {
            if (scope.closeSignal !== null) {
              scope.closeSignal.abort();
            }
            scope.getSearchPreview();
          },
          300,
          this
        );
      } else {
        this.hideSearchResults();
      }
    },
  },
};
</script>