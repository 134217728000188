<template>
  <div v-if="!isCookieAgreed" class="cookie-bar">
    <b-container>
      <div class="cookie-wrap">
        <div class="img">
          <b-img
            :src="require('@/base/assets/color-logo-big.svg')"
            fluid
            alt="Cookiebar bike"
          ></b-img>
        </div>

        <div class="text">
          <strong class="title">{{ $t("cookiebar_title") }}</strong>
          <p>
            {{ $t("cookiebar_text") }}
          </p>
          <div class="sub-wrap">
            <!-- <div class="checkbox-block">
              <label>
                <input type="checkbox" checked="checked" name="check01" />
                <span class="fake-check">{{ $t("cookiebar_necessary") }}</span>
              </label>
              <label>
                <input type="checkbox" v-model="allCookies" name="check02" />
                <span class="fake-check">{{ $t("cookiebar_other") }}</span>
              </label>
            </div> -->
            <div class="btn-wrap">
              <b-link class="btn btn-primary" @click="onClick(true)">{{
                $t("cookiebar_accept")
              }}</b-link>
              <b-link class="btn btn-white" @click="onClick(false)">{{
                $t("cookiebar_reject")
              }}</b-link>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
//import { isServer } from "@storefront/core/helpers";

export default {
  name: "CookieBarWrap",
  components: {},
  data() {
    return {
      allCookies: true,
      isCookieAgreed: this.$cookies.isKey("websiteCookie"),
    };
  },
  methods: {
    onClick(state) {
      if (state) {
        this.isCookieAgreed = true;
        this.$cookies.config("180d");
        this.$cookies.set("websiteCookie", true);
        this.$gtm.enable(true);
      } else {
        this.isCookieAgreed = true;
        this.$cookies.config("180d");
        this.$cookies.set("websiteCookie", false);
        this.$gtm.enable(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/base/scss/variables.scss";
.cookie-bar {
  background-color: $secondaryColor;
  color: $whiteColor;
  padding: 30px 0px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 150;
  .cookie-wrap {
    display: flex;
    align-items: center;
    .img {
      width: 400px;
    }
    .text {
      width: calc(100% - 400px);
      padding-left: 50px;
      strong {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 15px;
        display: block;
      }
      p {
        font-size: 14px;
        line-height: 23px;
      }
      .btn-wrap {
        margin: 0 -10px;
        .btn {
          margin: 0 10px;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .cookie-wrap {
      flex-wrap: wrap;
      width: 100%;
      .img {
        width: 130px !important;
        margin-bottom: 10px;
      }
      .text {
        width: 100% !important;
        padding-left: 0 !important;
        p {
          font-size: 12px;
          line-height: 17px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    padding: 20px 0;
    .cookie-wrap {
      flex-wrap: wrap;
      width: 100%;
      .img {
        width: 130px !important;
        margin-bottom: 10px;
      }
      .text {
        width: 100% !important;
        padding-left: 0 !important;
        p {
          font-size: 12px;
          line-height: 17px;
        }
      }
    }
  }
}
</style>
