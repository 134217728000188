<template>
  <div class="rating">
    <ul class="ratings-star">
      <li
        v-for="(isFill, idx) of rateStars"
        :key="idx"
        :class="isFill ? 'text-orange' : 'text-gray'"
      >
        <i class="fas fa-star"></i>
      </li>
    </ul>
    <div class="no-of-ratings">
      ({{ noOfRatings }})
      <!-- {{ $t("review") }} -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Rating",
  props: {
    stars: {
      type: Number,
    },
    noOfRatings: {
      type: Number,
    },
  },
  computed: {
    rateStars() {
      return new Array(5).fill(false).map((_, i) => i < this.stars / 10 / 2);
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@/base/scss/variables";
.ratings-star {
  display: flex;
  margin-bottom: 0;
}

.ratings-star .text-gray {
  color: $borderLightColor;
}

.ratings-star .text-gray.orange-text {
  color: $OrangeColor;
}
</style>
