<template>
  <ul class="top-icons">
    <li
      v-for="icon of icons"
      :key="icon.iconNumber"
      :class="openAccountClass(icon.menuId)"
    >
      <ClientOnly>
        <b-link
          v-if="icon.isEnabled"
          :to="icon.link"
          @click.prevent="onClick(icon)"
          v-b-toggle="`topIconsCollapse` + showType + icon.iconNumber"
          :class="`main-menu ${icon.noborder}`"
        >
          <i v-if="icon.isLinearIcon" class="fa" :class="icon.iconName"></i>
          <div class="img" v-else>
            <b-img
              :src="`${app_url}/media/${icon.iconName}`"
              :alt="icon.text"
              fluid
              width="35"
              height="35"
            ></b-img>
          </div>
          <b-badge v-if="icon.hasBadge" :variant="icon.badgeColor">{{
            icon.badgeValue
          }}</b-badge>
        </b-link>
      </ClientOnly>

      <HeaderIconsDropdownCart
        v-if="
          icon.hasDropdown && icon.menuId == 'cart' && icon.isEnabled == true
        "
        :icon="icon"
        :showType="showType"
      />
      <HeaderIconsDropdown
        v-if="
          icon.hasDropdown &&
          icon.menuId == 'wishList' &&
          icon.isEnabled == true
        "
        :icon="icon"
        :showType="showType"
      />
      <HeaderIconsDropdownMenu
        v-if="
          icon.hasDropdown && icon.menuId == 'account' && icon.isEnabled == true
        "
        :icon="icon"
        :showType="showType"
        :showAccount="showAccount"
        @closeAccountBar="showAccount = false"
      />
    </li>
  </ul>
</template>
<script>
import ClientOnly from "vue-client-only";
import { isMobile, isTablet } from "mobile-device-detect";
import HeaderIconsDropdown from "@/base/core/components/header/HeaderIconsDropdown";
import HeaderIconsDropdownCart from "@/base/core/components/header/HeaderIconsDropdownCart";
import HeaderIconsDropdownMenu from "@/base/core/components/header/HeaderIconsDropdownMenu";
import Cart from "@storefront/core/modules/cart/mixins";
import config from "@config";
export default {
  name: "HeaderTopIcons",
  mixins: [Cart],
  components: {
    ClientOnly,
    HeaderIconsDropdown,
    HeaderIconsDropdownCart,
    HeaderIconsDropdownMenu,
  },
  data: () => ({
    showAccount: false,
    app_url: config.app_url,
  }),
  methods: {
    onClick(icon) {
      if (icon.menuId === "wishList") {
        this.$store.commit("user/setAccountTabIndex", 2);
        this.$router.push("/account");
      }
      if (icon.menuId === "account") {
        if (this.mobileCheck) {
          this.showAccount = !this.showAccount;
          const account = this.icons.find((i) => i.menuId === "account");
          account.iconName =
            account.iconName === "fa-user" ? "fa-heart" : "fa-user";
        }
      }
    },
    openAccountClass(icon) {
      if (icon === "account") {
        return { "mob-myaccount": this.showAccount };
      }
    },
  },
  props: {
    showType: { type: String },
  },
  computed: {
    mobileCheck() {
      if (isMobile || isTablet) return true;
      return false;
    },
    icons() {
      return [
        {
          iconNumber: 1,
          isLinearIcon: false,
          hasBadge: false,
          iconName: "icoon_account_wit.svg",
          text: this.$t("login"),
          hasDropdown: false,
          link: "/login",
          isEnabled: !this.isLoggedIn,
          noborder: "noborder",
        },
        {
          iconNumber: 2,
          isLinearIcon: false,
          hasBadge: false,
          iconName: "icoon_account_wit.svg",
          text: this.$t("account"),
          menuId: "account",
          hasDropdown: true,
          isEnabled: this.isLoggedIn,
          noborder: "noborder",
        },
        {
          iconNumber: 3,
          isLinearIcon: false,
          hasBadge: true,
          badgeValue: this.wishlistCount,
          badgeColor: "success",
          iconName: "verlang_wit.svg",
          text: "Wensenlijst",
          hasDropdown: false,
          menuId: "wishList",
          isEnabled: this.isLoggedIn,
          dropHeading: this.$t("items_in_wishlist", {
            numberOf: this.wishlistCount,
          }),
          dropItems: this.wishlistItems,
        },
        {
          iconNumber: 4,
          isLinearIcon: false,
          hasBadge: true,
          badgeValue: this.cartCount,
          badgeColor: "success",
          iconName: "wagentje_wit.svg",
          text: "Winkelmand",
          isEnabled: true,
          hasDropdown: true,
          // hasDropdown: true,
          dropHeading: this.$t("items_in_cart", { numberOf: this.cartCount }),
          dropItems: this.cartItems,
          menuId: "cart",
          cartFooter: {
            totalPrice: this.cartSubTotalPrice,
            btnText: this.$t("goto_checkout"),
            btnVariant: "success",
          },
          noborder: "noborder",
          // link: "#",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.noborder {
  border: none !important;
}
</style>