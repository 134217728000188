<template>
  <header :id="headerID" class="">
    <div class="topHeader-content" >
      <div class="top-header py-3">
        <b-container>
          <div class="d-flex align-items-center justify-content-between">
            <div class="logo mr-lg-20">
              <b-link to="/"
                ><b-img
                  :src="require('../../../assets/color-logo.svg')"
                  :srcset="
                    require('!!url-loader?limit=0!../../../assets/color-logo.svg') +
                    ' 253w ,' +
                    require('!!url-loader?limit=0!../../../assets/color-logo.svg') +
                    ' 175w, ' +
                    require('!!url-loader?limit=0!../../../assets/color-logo.svg') +
                    ' 75w '
                  "
                  width="210"
                  height="28"
                  alt="Logo"
                ></b-img>
              </b-link>
            </div>
            <div
              class="
                d-flex
                align-items-center
                justify-content-end
                search-and-icons
              "
              v-if="!ischeckoutpage"
            >
              <HeaderSearch />
            </div>
            <HeaderTopIcons showType="O" />
          </div>
        </b-container>
      </div>
      <div class="main-header green-bg" v-if="!ischeckoutpage">
        <b-container>
          <Navbar  />
        </b-container>
      </div>
    </div>
    <InformativeLine />
  </header>
</template>

<script>
import HeaderSearch from "@/base/core/components/header/HeaderSearch";
import HeaderTopIcons from "@/base/core/components/header/HeaderTopIcons";
import Navbar from "@/base/core/components/header/Navbar";
import InformativeLine from "@/base/core/components/common/InformativeLine";
export default {
  name: "Header",
  components: {
    HeaderSearch,
    HeaderTopIcons,
    Navbar,
    InformativeLine,
  },
  computed: {
    headerID(){
      if(this.ischeckoutpage == true){
        return 'header-checkout';
      }
      else{
        return 'header';
      }
    }
  },
  data: () => ({
    ischeckoutpage: false
  }),
  created(){
    if(this.$route.path == '/checkout'){
      this.ischeckoutpage = true
    }
    else{
      this.ischeckoutpage = false
    }
  }
}
</script>
