<template>
  <b-collapse
    :id="'topIconsCollapse' + showType + icon.iconNumber"
    :class="{ collapsed: visible }"
    :aria-expanded="visible"
    class="top-icons--drop"
    v-model="visible"
  >
    <div v-if="this.cartCount > 0">
      <span v-if="icon.dropHeading" class="drop-heading">{{
        icon.dropHeading
      }}</span>

      <ul class="cart-drop-item">
        <li v-for="dropItem of icon.dropItems" :key="dropItem.dropItemId">
          <div class="">
            <!-- <div class="drop-item--img">
            <b-img
              :src="dropItem.product.thumbnail.thumbnail"
              :alt="dropItem.product.name"
              width="39"
              height="39"
            >
            </b-img>
          </div> -->
            <div class="top">
              <b-link @click.prevent="removeProduct(dropItem.id)"
                ><b-icon icon="trash"></b-icon
              ></b-link>
            </div>
            <div class="drop-item--info">
              <div class="product-info">
                <span class="product-name d-block">{{
                  dropItem.product.name
                }}</span>
              </div>

              <div v-if="typeof dropItem.configurable_options != 'undefined'">
                <span
                  v-for="(option, index) of dropItem.configurable_options"
                  :key="index"
                  class="product-options d-block"
                >
                  <span class="product-options-title"
                    >{{ option.option_label }}:&nbsp;</span
                  >
                  <span class="product-options-value">{{
                    option.value_label
                  }}</span>
                </span>
              </div>
              <div v-if="typeof dropItem.bundle_options != 'undefined'">
                <div>
                  <span
                    v-for="(option, index) of dropItem.bundle_options"
                    :key="index"
                    class="product-options d-block"
                  >
                    <span>{{ option.label }}: </span>

                    <span
                      v-if="
                        typeof option.values[0].configurable_options !=
                        'undefined'
                      "
                    >
                      <span
                        v-for="(opt, idx) of option.values[0]
                          .configurable_options"
                        :key="idx"
                      >
                        <span class="product-options-title"
                          >{{ opt.option_label }}&nbsp;:</span
                        >
                        <span class="product-options-value">{{
                          opt.value_label
                        }}</span>
                      </span>
                    </span>
                  </span>
                </div>
                <span
                  v-for="(option, index) of dropItem.configurable_options"
                  :key="index"
                  class="product-options d-block"
                >
                  <span class="product-options-title"
                    >{{ option.option_label }}&nbsp;:</span
                  >
                  <span class="product-options-value">{{
                    option.value_label
                  }}</span>
                </span>
              </div>

              <div class="quantity-input d-flex">
                <div class="quantity-wrap">
                  <div class="action-wrap">
                    <b-link
                      class="quantity-btn step-up-btn"
                      @click="increaseQty(dropItem.id)"
                      ><i class="fas fa-sort-up"></i
                    ></b-link>
                    <b-link
                      class="quantity-btn step-down-btn"
                      @click="decreaseQty(dropItem.id)"
                      ><i class="fas fa-sort-down"></i
                    ></b-link>
                  </div>
                  <div class="action-output">
                    <input
                      type="number"
                      min="1"
                      :value="dropItem.quantity"
                      name="quantity"
                      :ref="'cartQuantity' + dropItem.id"
                      disabled
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="price-box price">
                {{ formatPrice(dropItem.prices.row_total_including_tax.value) }}
              </div>
            </div>
          </div>
        </li>
      </ul>

      <div class="total">
        <span>
          {{ $t("total") }}
          <span class="totalvat"> {{ $t("totalvat") }} </span> :
          {{ formatPrice(icon.cartFooter.totalPrice) }}
        </span>
      </div>

      <div
        class="d-flex drop-bottom justify-content-between align-items-center"
        v-if="icon.cartFooter"
      >
        <div class="go-cart">
          <i class="fas fa-chevron-left"></i>
          <b-link @click="goToCart">{{ $t("goto_cart") }}</b-link>
        </div>

        <b-button
          variant="success"
          @click="goToCheckout"
          v-bind:disabled="btnDisable"
        >
          {{ icon.cartFooter.btnText }}
        </b-button>
      </div>

      <div class="language-block" v-if="icon.language">
        <ul class="d-flex list-unstyled">
          <li v-for="lang of icon.language" :key="lang.alt">
            <b-link :href="lang.href">
              <img :src="lang.img" :alt="lang.alt" />
            </b-link>
          </li>
        </ul>
      </div>
    </div>
    <div v-else class="no-cart-item">
      <p>{{ $t("nothing_in_cart") }}</p>
    </div>
  </b-collapse>
</template>

<script>
//import { Logger } from "@storefront/core/lib/logger";
import Cart from "@storefront/core/modules/cart/mixins";

import { mapActions } from "vuex";
export default {
  name: "HeaderIconsDropdown",
  props: {
    icon: {
      type: Object,
    },
    showType: { type: String },
  },
  mixins: [Cart],
  computed: {
    btnDisable() {
      if (this.cartCount > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.closeDropdownMenu);
  },
  destroyed() {
    document.removeEventListener("click", this.closeDropdownMenu);
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    ...mapActions(["setProductStatus"]),
    increaseQty(id) {
      const qty = parseInt(this.$refs["cartQuantity" + id][0].value);
      this.$refs["cartQuantity" + id][0].value = qty + 1;
      this.quantityUp(id, qty);
    },
    decreaseQty(id) {
      const qty = parseInt(this.$refs["cartQuantity" + id][0].value);
      if (qty > 1) {
        this.$refs["cartQuantity" + id][0].value = qty - 1;
        this.quantityDown(id, qty);
      }
    },
    closeDropdownMenu(e) {
      if (!this.$el.parentElement.contains(e.target)) {
        this.visible = false;
      }
    },
    goToCart() {
      this.visible = false;
      this.$router.push("/checkout/cart");
    },
    goToCheckout() {
      this.visible = false;
      this.$router.push("/checkout");
    },
    formatPrice(price) {
      if (price % 1 === 0) {
        return "€ " + price + ",-";
      }
      return "€ " + price.toFixed(2).replace(".", ",");
    },
    removeProduct(cartItem) {
      const cartItemCount = this.$store.getters["cart/getItemsTotalQuantity"];
      this.removeItem(cartItem);
      if (cartItemCount - 1 == 0) {
        this.$store.commit("product1/setServicePostcode", "");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
