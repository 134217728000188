<template>
  <div>
    <!-- <cmsBlockSimple
      v-if="typeof navRightCms == 'object'"
      :identifier="navRightCms.identifier"
    /> -->
    <v-runtime-template
      v-if="typeof navRightCms == 'object'"
      :template="`<div class='cms-content'>${navRightCms.content}</div>`"
    ></v-runtime-template>
  </div>
</template>

<script>
import VRuntimeTemplate from "v-runtime-template";
// import cmsBlockSimple from "@/base/core/components/core/BlockSimple";

export default {
  name: "NavbarRightMenu",
  components: {
    // cmsBlockSimple,
    VRuntimeTemplate,
  },
  computed: {
    navRightCms() {
      const retval = {
        ...this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
          "header_links"
        ),
      };

      retval.content = retval.content.replace(/<a href=/g, "<b-link to=");
      retval.content = retval.content.replace(/<a/g, "<b-link");
      retval.content = retval.content.replace(/<\/a>/g, "</b-link>");
      retval.content = retval.content.replace(/ href="\//g, ' to="/');
      retval.content = retval.content.replace(
        /<b-link((?!<\/b-link>).)to="(http|mailto|tel)(.*?)<\/b-link>/g,
        '<a$1href="$2$3</a>'
      );
      return retval;
    },
  },
};
</script>

<style>
</style>