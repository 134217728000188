<template>
  <div :class="informativeSliderClass" >
    <div class="container">
      <div
        class="informative-slider"
        v-if="typeof informativeSlider != 'undefined'"
      >
        <ClientOnly>
          <VueSlickCarousel
            :arrows="false"
            :dots="false"
            :slidesToShow="3"
            :autoplay="true"
            :responsive="[
              {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 540,
                settings: {
                  slidesToShow: 1,
                },
              },
            ]"
          >
            <div
              class="cms-block-content"
              v-for="(slide, index) in informativeSlider.slides"
              :key="index"
            >
              <b-link
                :to="slide.link"
                class=""
                v-if="slide.link && slide.link.substring(0, 4) !== 'http'"
              >
                <div v-html="slide.description"></div>
              </b-link>
              <b-link
                :href="slide.link"
                target="_blank"
                class=""
                rel="noopener noreferrer"
                v-if="slide.link && slide.link.substring(0, 4) === 'http'"
              >
                <div v-html="slide.description"></div>
              </b-link>
              <b-link to="" class="" v-if="!slide.link">
                <div v-html="slide.description"></div>
              </b-link>
            </div>
          </VueSlickCarousel>
        </ClientOnly>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ClientOnly from "vue-client-only";
// import cmsBlockSimple from "@/base/core/components/core/BlockSimple";

import { isMobile } from "mobile-device-detect";
import { isTablet } from "mobile-device-detect";
export default {
  name: "InformativeLine",
  components: {
    VueSlickCarousel,
    // cmsBlockSimple,
    ClientOnly,
  },
  props: {
    isSlider: Boolean,
  },
  computed: {
    mobileCheck() {
      if (isMobile || isTablet) {
        return true;
      }
      return false;
    },
    headerInforativeCms() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "header_informative_text"
      );
    },
    informativeSlider() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "informative_text_slider"
      );
    },
    informativeSliderClass(){
      if(this.ischeckoutpage == true){
        return 'informative-sec-checkout';
      }
      else{
        return 'informative-sec-test';
      }
    }
  },
  data: () => ({
    ischeckoutpage: false
  }),
  created(){
    if(this.$route.path == '/checkout'){
      this.ischeckoutpage = true
    }
    else{
      this.ischeckoutpage = false
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
