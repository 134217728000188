<template>
  <div id="app">
    <!-- <div
      v-if="generalMsg.text != ''"
      class="top-banner"
      :style="
        `background-color:` +
        generalMsg.background_color +
        `; color:` +
        generalMsg.text_color +
        `;`
      "
    >
      <span class="texttop" v-html="generalMsg.text"></span>
    </div> -->

    <Header />
    <router-view />
    <Footer />
    <ClientOnly>
      <CookieBarWrap />
    </ClientOnly>
  </div>
</template>
<script>
import Header from "@/base/core/components/header/Header.vue";
import Footer from "@/base/core/components/footer/Footer";
import CookieBarWrap from "@/base/core/components/common/CookieBar.vue";
import ClientOnly from "vue-client-only";

import { isServer } from "@storefront/core/helpers";
import { Logger } from "@storefront/core/lib/logger";
import config from "@config";

const contentList = [
  //  "side-menu-cms",
  "header_informative_text",
  "header_links",
  "footer_menu_information",
  "footer_menu_service",
  "footer_menu_quick_links",
  "footer_contact",
  "footer_top_content",
  "home_page_special_offers",
  "home_page_advertise_block",
  "customer_service",
  "exchange_returns",
  "customer_service_image",
  "customer_service_contact_info",
  "terms_and_condition",
  "page_not_found",
  "footer_image",
  "klantenservice",
  "thank_you_page_image",
  "footer_bottom_menu",
  "footer_bottom_menu",
  "footer_checkout_info",
  "pdp_usp",
  "checkout_payment_succes_text",
  "single_blog_content",
  //  "free_shipping",
  //  "our_advantages",
  //  "discount_header",
  //  "home_pictures",
  //  "header_seperator_title",
  //  "contact_page_block",
  //  "merken",
  // "footer_service",
  // "footer_stores",
];
const sliders = [
  "home",
  "home_gallery",
  "category_slider",
  "payment_providers",
  "informative_text_slider",
  "home_page_special_offers_slider",
  "social_icons",
  "home_2_blocks",
  "productusp_text_slider",
  "blog_banner",
];

export default {
  name: "Home",
  metaInfo() {
    return {
      htmlAttrs: { lang: this.$i18n.locale },
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
      titleTemplate: "%s | " + config.app_name,
    };
  },
  computed: {
    cmsDefaultPageContent() {
      return this.$store.state.cmsPage.default;
    },
    generalMsg() {
      return this.$store.state.messages.generalMsg;
    },
  },
  watch: {
    "$store.state.messages.msgItems": async function () {
      Logger.debug("App", "watch", "IsCalled")();
      const msgs = await this.$store.dispatch("messages/giveLastMessages");
      Logger.debug("App", "watch", typeof msgs)();
      Logger.debug("App", "watch", msgs)();
      msgs.forEach(function (msg) {
        Logger.debug("app", "watch", msg)();
        this.makeToast(msg);
      }, this);
    },
  },
  components: {
    Header,
    Footer,
    CookieBarWrap,
    ClientOnly,
  },
  async serverPrefetch() {
    this.$store.dispatch("forms/loadForms");
    this.$store.dispatch("faq/load");
    await this.$store.dispatch("messages/loadGeneralMsg");
    await this.$store.dispatch("product/loadBrandSlider");
    await this.$store.dispatch("menu/loadMenu");
    await this.$store.dispatch("user/loadCountries");
    await this.$store.dispatch("product/productReviewRatingsMetadata");
    await this.$store.dispatch("stores1/load");
    // await this.$store.dispatch("stores/load");
    //await this.$store.dispatch("home/loadBundles");
    await this.$store.dispatch("blog/load");
    await this.$store.dispatch("home/loadTopWeekProducts");
    await this.$store.dispatch("cmsPage/loadDefault", {
      id: "home",
    });
    await this.$store.dispatch("sliders/multiple", {
      key: "identifier",
      value: sliders,
    });

    await this.$store.dispatch("cmsBlock/multiple", {
      key: "identifier",
      value: contentList,
    });
    await this.$store.dispatch("cart/loadPickupLocations");
  },
  async beforeCreate() {
    /*
    Logger.debug("beforeCreate", "App.vue", "start")();
    Logger.debug("beforeCreate", "App.vue", "start sliders")();
    await this.$store.dispatch("sliders/multiple", {
      key: "identifier",
      value: sliders,
    });
    Logger.debug("beforeCreate", "App.vue", "stop sliders")();

    await this.$store.dispatch("cmsBlock/multiple", {
      key: "identifier",
      value: contentList,
    });

    this.$store.dispatch("forms/loadForms");
    this.$store.dispatch("faq/load");
    await this.$store.dispatch("messages/loadGeneralMsg");
    await this.$store.dispatch("product/loadBrandSlider");
    await this.$store.dispatch("menu/loadMenu");
    await this.$store.dispatch("user/loadCountries");
    await this.$store.dispatch("product/productReviewRatingsMetadata");
    //await this.$store.dispatch("stores/load");
    //await this.$store.dispatch("home/loadBundles");
    //await this.$store.dispatch("blog/load");
    await this.$store.dispatch("home/loadTopWeekProducts");
    await this.$store.dispatch("cmsPage/loadDefault", {
      id: "home",
    });
    await this.$store.dispatch("cart/loadPickupLocations");
    */
  },
  created() {
    if (!isServer) {
      Logger.debug(
        "wesiteCookie",
        "App.vue",
        this.$cookies.get("websiteCookie")
      )();
      if (this.$cookies.get("websiteCookie") == "true") {
        this.$gtm.enable(true);
        this.$gtm.debug(true);
        Logger.debug("gtm1", "App.vue", this.$gtm.enabled())();
      }
      if (config.uaDisabled) {
        this.$gtm.uaDisabled = true;
      } else {
        this.$gtm.uaDisabled = false;
      }
      if (config.ga4enabled) {
        this.$gtm.ga4Enabled = true;
      } else {
        this.$gtm.ga4Enabled = false;
      }
    }
  },
  async mounted() {
    this.$store.dispatch("cart/loadCart");
    this.$store.dispatch("user/loadUser");

    if (!isServer) {
      let ratingScript = document.createElement("script");
      ratingScript.async = true;
      ratingScript.src =
        "//apps.bazaarvoice.com/deployments/eptummers/main_site/production/nl_NL/bv.js";
      document.head.appendChild(ratingScript);
    }
  },
  methods: {
    makeToast(msg) {
      this.$bvToast.toast(msg.text, {
        //title: msg.title,
        variant: msg.type,
        autoHideDelay: 5000,
        toaster: "b-toaster-top-center",
        solid: true,
        append: true,
      });
    },
  },
};
</script>

<style lang="scss"></style>
