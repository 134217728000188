<template>
  <div
    class="product-range-submenu"
    ref="menu"
    :style="{ height: !mobileCheck && menuHeight.current + 30 + 'px' }"
  >
    <ul>
      <li
        v-for="navItem of navItems"
        :key="navItem.id"
        @mouseover="onOver(navItem.id)"
        @mouseleave="onLeave(navItem.id)"
      >
        <div
          v-if="mobileCheck"
          @click.stop="onToggle(navItem.id)"
          class="mobile-main-menu"
        >
          <div class="nav-link">
            <b-link
              @click="onClick(navItem.id, navItem.linkTo, navItem.hasDropMenu)"
              >{{ navItem.itemName }}</b-link
            >
          </div>
          <div
            v-if="navItem.hasDropMenu"
            class="arrow-wrap"
            :class="{ expanded: isActiveNavItem(navItem.id) }"
          ></div>
        </div>

        <div v-else>
          <div class="nav-link">
            <b-link
              @click.stop="
                onClick(navItem.id, navItem.linkTo, navItem.hasDropMenu)
              "
              >{{ navItem.itemName }}
              <span v-if="navItem.hasDropMenu"
                ><i class="fa fa-chevron-right"></i
              ></span>
            </b-link>
          </div>
        </div>

        <HeaderSubmenu
          v-if="navItem.hasDropMenu"
          :menuArray="navItem.dropMenu"
          :collapseId="'submenu' + navItem.id"
          :id="navItem.id"
          @setHeight="setHeight"
          @resetHeight="resetHeight"
        />
      </li>
    </ul>
    <NavbarRightMenu class="mobile-navlinks" />
  </div>
</template>

<script>
import { Logger } from "@storefront/core/lib/logger";
import { isMobile, isTablet } from "mobile-device-detect";

import HeaderSubmenu from "@/base/core/components/header/HeaderSubmenu";
import NavbarRightMenu from "@/base/core/components/header/NavbarRightMenu";

export default {
  name: "NavbarContent",
  components: {
    HeaderSubmenu,
    NavbarRightMenu,
  },
  // props: ["showNavContent"],
  data: () => ({
    menuHeight: {
      default: 315,
      current: 315,
    },
  }),
  mounted() {
    // console.log("InN MENU", this.$refs.menu.clientHeight);
    // this.$refs.menu.clientHeight = "500px";
  },
  computed: {
    navItems() {
      return this.$store.getters["menu/getMenuItems"];
    },
    mobileCheck() {
      if (isMobile || isTablet) {
        return true;
      }
      return false;
    },
  },
  methods: {
    onOver(id) {
      // window.innerWidth > 991
      if (!this.mobileCheck) {
        this.$store.commit("menu/updateMenuState", { id: id, state: true });
      }
    },
    onToggle(id) {
      // window.innerWidth <= 991
      const vis = this.$store.getters["menu/getMenuItemState"](id);
      Logger.debug("onToggle id", "Navbar", vis.id)();
      Logger.debug("onToggle", "Navbar", vis.state)();

      if (vis.state == false) {
        this.$store.commit("menu/updateMenuState", { id: id, state: true });
      } else {
        this.$store.commit("menu/updateMenuState", { id: id, state: false });
      }
    },
    onLeave(id) {
      // window.innerWidth > 991
      if (!this.mobileCheck) {
        this.$store.commit("menu/updateMenuState", { id: id, state: false });
      }
    },
    doMouseOver(id) {
      Logger.debug("Mouse Over", "Menu", id)();
    },
    onClick(id, route, hasDropMenu) {
      if (!hasDropMenu) {
        Logger.debug("onClick", "navbarContent", id)();
        Logger.debug("onClick", "navbarContent", route)();
        Logger.debug(
          "onClick menu item state",
          "navbarContent",
          this.$store.getters["menu/getMenuItemState"](id).state
        )();
        this.$store.commit("menu/updateMenuState", { id: id, state: false });
        Logger.debug(
          "onClick menu item state",
          "navbarContent",
          this.$store.getters["menu/getMenuItemState"](id).state
        )();
        this.$store.commit("menu/setNavBar", false);

        this.$emit("toggleNav");

        this.$router.push(route);
      }
    },
    isActiveNavItem(id) {
      return this.$store.getters["menu/getMenuItemState"](id).state;
    },
    setHeight(height) {
      if (height > this.menuHeight.default) {
        this.menuHeight.current = height;
      } else {
        this.resetHeight();
      }
    },
    resetHeight() {
      this.menuHeight.current = this.menuHeight.default;
    },
    // toggleNav(event) {
    //   this.$emit("clicked", event);
    // },
  },
};
</script>

<style lang="scss" scoped></style>
