var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"top-icons"},_vm._l((_vm.icons),function(icon){return _c('li',{key:icon.iconNumber,class:_vm.openAccountClass(icon.menuId)},[_c('ClientOnly',[(icon.isEnabled)?_c('b-link',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:("topIconsCollapse" + _vm.showType + icon.iconNumber),expression:"`topIconsCollapse` + showType + icon.iconNumber"}],class:("main-menu " + (icon.noborder)),attrs:{"to":icon.link},on:{"click":function($event){$event.preventDefault();return _vm.onClick(icon)}}},[(icon.isLinearIcon)?_c('i',{staticClass:"fa",class:icon.iconName}):_c('div',{staticClass:"img"},[_c('b-img',{attrs:{"src":(_vm.app_url + "/media/" + (icon.iconName)),"alt":icon.text,"fluid":"","width":"35","height":"35"}})],1),(icon.hasBadge)?_c('b-badge',{attrs:{"variant":icon.badgeColor}},[_vm._v(_vm._s(icon.badgeValue))]):_vm._e()],1):_vm._e()],1),(
        icon.hasDropdown && icon.menuId == 'cart' && icon.isEnabled == true
      )?_c('HeaderIconsDropdownCart',{attrs:{"icon":icon,"showType":_vm.showType}}):_vm._e(),(
        icon.hasDropdown &&
        icon.menuId == 'wishList' &&
        icon.isEnabled == true
      )?_c('HeaderIconsDropdown',{attrs:{"icon":icon,"showType":_vm.showType}}):_vm._e(),(
        icon.hasDropdown && icon.menuId == 'account' && icon.isEnabled == true
      )?_c('HeaderIconsDropdownMenu',{attrs:{"icon":icon,"showType":_vm.showType,"showAccount":_vm.showAccount},on:{"closeAccountBar":function($event){_vm.showAccount = false}}}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }