<template>
  <b-collapse
    v-if="!mobileCheck"
    :id="'topIconsCollapse' + showType + icon.iconNumber"
    :class="{ collapsed: visible }"
    :aria-expanded="visible"
    class="top-icons--drop user-dropdown"
    v-model="visible"
  >
    <div class="">
      <ul class="list-unstyled">
        <li>
          <b-link @click="goAccount">{{ $t("account") }}</b-link>
        </li>
        <li>
          <b-link @click="goLogout">{{ $t("logout") }}</b-link>
        </li>
      </ul>
    </div>
  </b-collapse>
  <div v-else class="mobile-myaccount-menu">
    <ul v-if="showAccount">
      <li>
        <b-link @click="goToTab(0)">{{ $t("my_account") }}</b-link>
        <i class="fa fa-chevron-right"></i>
      </li>
      <li>
        <b-link @click="goToTab(1)">{{ $t("my_orders") }}</b-link>
        <i class="fa fa-chevron-right"></i>
      </li>
      <li>
        <b-link @click="goToTab(2)">{{ $t("my_wishlist") }}</b-link>
        <i class="fa fa-chevron-right"></i>
      </li>
      <li>
        <b-link @click="goToTab(3)">{{ $t("directory") }}</b-link>
        <i class="fa fa-chevron-right"></i>
      </li>
      <li>
        <b-link @click="goToTab(4)">{{ $t("contact_detail") }}</b-link>
        <i class="fa fa-chevron-right"></i>
      </li>
      <li>
        <b-link @click="goToTab(5)">{{ $t("change_email") }}</b-link>
        <i class="fa fa-chevron-right"></i>
      </li>
      <li>
        <b-link @click="goToTab(6)">{{ $t("change_password") }}</b-link>
        <i class="fa fa-chevron-right"></i>
      </li>
      <li>
        <b-link @click="goToTab(7)">{{ $t("product_review") }}</b-link>
        <i class="fa fa-chevron-right"></i>
      </li>
      <li>
        <b-link @click="goToTab(8)">{{ $t("newsletter") }}</b-link>
        <i class="fa fa-chevron-right"></i>
      </li>
      <li>
        <b-link @click="goLogout">Logout</b-link>
        <i class="fa fa-chevron-right"></i>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { isMobile, isTablet } from "mobile-device-detect";
export default {
  name: "HeaderIconsDropdown",
  props: {
    icon: {
      type: Object,
    },
    showType: { type: String },
    showAccount: { type: Boolean },
  },
  mounted() {
    document.addEventListener("click", this.closeDropdownMenu);
  },
  destroyed() {
    document.removeEventListener("click", this.closeDropdownMenu);
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    mobileCheck() {
      if (isMobile || isTablet) return true;
      return false;
    },
  },
  methods: {
    ...mapActions(["setProductStatus"]),
    closeDropdownMenu(e) {
      if (!this.$el.parentElement.contains(e.target)) {
        this.visible = false;
      }
    },
    removeItem(id) {
      this.$store.dispatch("user/removeProductWishlist", {
        id: id,
      });
    },
    closeOnClick() {
      this.visible = false;
    },
    goAccount() {
      this.visible = false;
      this.$router.push("/account");
    },
    goToTab(tabIndex) {
      this.$store.commit("user/setAccountTabIndex", tabIndex);
      this.$router.push("/account");
      this.$emit("closeAccountBar");
    },
    goLogout() {
      this.visible = false;
      this.$store.dispatch("user/logout");
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped></style>
