<template>
  <div class="footer-bottom">
    <div class="container">
      <div class="payment text-center py-2">
        <!-- <span class="text-uppercase block-heading d-block">{{
          $t("secure_payments")
        }}</span> -->
        <div v-if="typeof paymentPartners == 'object'">
          <ul class="payment-partners">
            <li v-for="(image, index) in paymentPartners.slides" :key="index">
              <b-link
                class="img"
                :href="image.link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  :src="image.media.url"
                  :alt="image.title"
                  class="delivery-icons"
                />
              </b-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="copyright-content py-3">
      <div class="container">
        <div class="d-flex justify-content-between align-items-center mob-wrap">
          <div class="copyright">
            {{ $t("copyright") }}
          </div>
          <div class="policy-list">
            <cmsBlockSimple
              v-if="typeof footerBottomMenu == 'object'"
              :identifier="footerBottomMenu.identifier"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cmsBlockSimple from "@/base/core/components/core/BlockSimple";

export default {
  name: "FooterBottom",
  components: {
    cmsBlockSimple,
  },
  computed: {
    paymentPartners() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "payment_providers"
      );
    },
    footerBottomMenu() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "footer_bottom_menu"
      );
    },
  },
};
</script>

<style>
</style>