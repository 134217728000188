import { render, staticRenderFns } from "./InformativeLine.vue?vue&type=template&id=418e63d4&scoped=true&"
import script from "./InformativeLine.vue?vue&type=script&lang=js&"
export * from "./InformativeLine.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "418e63d4",
  null
  
)

export default component.exports