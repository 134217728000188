<template>
  <div>
    <!-- mobile{{ mob }} tab {{ mobtab }} overall {{ mobileCheck }} -->
    <!-- ttt {{ readMore }} -->
    <b-collapse
      :id="collapseId"
      class="sub-menu fancy-scroll"
      v-model="visible"
    >
      <div v-if="!mobileCheck" ref="sub-menu">
        <ul v-for="menu in menuArray" :key="'menu' + menuArray.indexOf(menu)">
          <li v-for="menuItem in menu" :key="menuItem.id">
            <!-- @mouseover="onOver(menuItem.id)"
            @mouseleave="onLeave" -->
            <!-- @mouseleave="onLeave" -->
            <b-link v-on:click="closeInternal" :to="menuItem.linkTo">{{
              menuItem.name
            }}</b-link>
            <!-- <ul v-if="showSubMenu" class="submenu-child">
              <li v-for="subMenuItem in subMenuItems" :key="subMenuItem.id">
                <b-link :to="subMenuItem.url_path">{{
                  subMenuItem.name
                }}</b-link>
              </li>
            </ul> -->
            <ul class="submenu-child">
              <b-collapse
                visible
                :id="`sub-collaps${menuItem.id}`"
                class="brands-filter"
              >
                <li v-for="(subItem, i) in menuItem.dropMenu" :key="subItem.id">
                  <div v-if="i < 2">
                    <b-link
                      v-if="subItem.include_in_menu"
                      :to="`/${subItem.url_path}`"
                      >{{ subItem.name }}</b-link
                    >
                  </div>
                  <div v-else>
                    <div v-if="readMore[menuItem.id]">
                      <b-link
                        v-if="subItem.include_in_menu"
                        :to="`/${subItem.url_path}`"
                        >{{ subItem.name }}</b-link
                      >
                    </div>
                  </div>
                </li>

                <div v-if="menuItem.dropMenu.length > 2">
                  <div v-if="!readMore[menuItem.id]">
                    <b-link
                      class="filter--block-heading"
                      style="display: block; cursor: pointer"
                      @click.stop="showMore(menuItem.id)"
                      >{{ $t("show_more") }}</b-link
                    >
                  </div>

                  <div v-if="readMore[menuItem.id]">
                    <b-link
                      class="filter--block-heading"
                      style="display: block; cursor: pointer"
                      @click.stop="showLess(menuItem.id)"
                    >
                      Toon minder
                    </b-link>
                  </div>
                </div>
              </b-collapse>
            </ul>
          </li>
        </ul>
      </div>
      <div v-else>
        <ul v-for="menu in menuArray" :key="'menu' + menuArray.indexOf(menu)">
          <li v-for="menuItem in menu" :key="menuItem.id">
            <div class="mobile-sub-wrap">
              <b-link @click="closeInternal" :to="menuItem.linkTo">{{
                menuItem.name
              }}</b-link>
              <b-link @click.stop v-b-toggle="`subMenu${menuItem.id}`">
                <i
                  v-if="menuItem.dropMenu.length"
                  class="fa fa-chevron-right"
                ></i
              ></b-link>
            </div>
            <b-collapse
              v-if="menuItem.dropMenu.length"
              :id="`subMenu${menuItem.id}`"
            >
              <ul class="submenu-child">
                <li v-for="subItem in menuItem.dropMenu" :key="subItem.id">
                  <b-link
                    v-if="subItem.include_in_menu"
                    :to="`/${subItem.url_path}`"
                    >{{ subItem.name }}</b-link
                  >
                </li>
              </ul>
            </b-collapse>
          </li>
        </ul>
      </div>
    </b-collapse>
  </div>
</template>

<script>
//import { Logger } from "@storefront/core/lib/logger";
import { isMobile, isTablet } from "mobile-device-detect";

export default {
  name: "HeaderSubmenu",
  components: {},
  props: ["menuArray", "collapseId", "modelValue", "id"],
  data: () => ({
    // visible: false,
    me: this,
    subMenuId: null,
    readMore: {},
  }),
  computed: {
    mobileCheck() {
      if (isMobile || isTablet) {
        return true;
      }
      return false;
    },
    mob() {
      if (isMobile) {
        return true;
      }
      return false;
    },
    mobtab() {
      if (isTablet) {
        return true;
      }
      return false;
    },
    visible: {
      get() {
        // return true;
        return this.$store.getters["menu/getMenuItemState"](this.id).state;
      },
      set(val) {
        this.$store.commit("menu/updateMenuState", { id: this.id, state: val });
      },
    },
  },
  methods: {
    showMore(id) {
      this.$set(this.readMore, id, true);
      this.setMenuHeight();
    },
    showLess(id) {
      this.$set(this.readMore, id, false);
      this.setMenuHeight();
    },
    close(e) {
      if (!this.$el.parentElement.contains(e.target)) {
        this.visible = false;
        //this.$store.commit("menu/setNavBar", false);
      }
    },
    closeInternal() {
      this.visible = false;
      // this.$store.commit("menu/setNavBar", false);
    },
    onOver(subMenuId) {
      this.subMenuId = subMenuId;
    },
    onLeave() {
      this.subMenuId = null;
    },
    setMenuHeight() {
      this.$nextTick(() => {
        const subMenu = this.$refs["sub-menu"];
        if (subMenu) {
          const height = subMenu.clientHeight;
          this.$emit("setHeight", height);
        }
      });
    },
    resetMenuHeight() {
      this.$emit("resetHeight");
    },
  },
  mounted() {
    document.addEventListener("click", this.close);
  },
  watch: {
    visible(value) {
      if (value == true) {
        this.setMenuHeight();
      }
      if (value == false) {
        this.resetMenuHeight();
      }
    },
  },
  destroyed() {
    document.removeEventListener("click", this.close);
  },
};
</script>

<style lang="scss" scoped></style>
